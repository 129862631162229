<template>
  <validation-observer
    ref="shippingOptionsCalculator"
    tag="form"
  >
    <div class="checkout-options">
      <b-card
        :title="$t(`modules.shipping_methods._list.preview`)"
        :sub-title="$t(`modules.shipping_methods._list.preview_subtitle`)"
      >
        <small
          v-t="`modules.shipping_methods._list.preview_description`"
          class="section-description mb-1"
        />

        <div
          class="justify-content-between align-items-center my-1"
        >
          <i-input
            v-model="cityId"
            title="modules.shipping_methods.inputs.target_city"
            type="select"
            :options="cities"
            label="name"
            :reduce="city => city.id"
            :disabled="isLoading"
            required
          />
          <validation-provider
            #default="{ errors }"
            :name="$t(`modules.shipping_methods.inputs.total_order_weight`)"
            rules="required"
          >
            <b-form-group
              :label="$t(`modules.shipping_methods.inputs.total_order_weight`)"
              label-for="total_order_weight`"
            >
              <b-input-group
                append="KG"
                :class="{'is-invalid': errors && errors.length}"
              >
                <b-form-input
                  id="total_order_weight"
                  v-model="weight"
                  type="number"
                  :placeholder="$t(`modules.shipping_methods.inputs.total_order_weight`)"
                  step="0.1"
                  required
                  :state="errors.length > 0 ? false:null"
                  :disabled="isLoading"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-center">
            <b-button
              class="m-auto"
              variant="outline-primary"
              :disabled="isLoading"
              @click="onSubmit"
            >
              <span v-t="'Calculate'" />
            </b-button>
          </div>
        </div>

        <template v-if="options !== null">
          <hr>
          <shipping-options
            v-if="options.length"
            accordion
            type="border"
          >
            <shipping-options-item
              v-for="option in options"
              :key="`option_${option.code}`"
              :title="option.name"
              :charges="option.charges"
            >
              {{ option.note }}
            </shipping-options-item>
          </shipping-options>
          <p
            v-else
            v-t="'modules.shipping_methods._messages.no_shipping_options_available'"
          />
        </template>
      </b-card>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { onMounted, onUnmounted, ref } from 'vue'
import ShippingOptions from '@/views/models/shipping_methods/list/ShippingOptions.vue'
import ShippingOptionsItem from '@/views/models/shipping_methods/list/ShippingOptionsItem.vue'
import store from '@/store'
import shippingMethodStoreModule from '@/views/models/shipping_methods/shippingMethodStoreModule'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    ShippingOptions,
    ShippingOptionsItem,
  },
  props: {
    cities: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  data() {
    return {
    }
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'shipping_methods'

    // Register module
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, shippingMethodStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)
    const options = ref(null)
    const cityId = ref(null)
    const weight = ref(null)
    const shippingOptionsCalculator = ref()

    const onSubmit = () => {
      shippingOptionsCalculator.value.validate().then(isSuccess => {
        if (isSuccess) {
          store.dispatch(`${STORE_MODULE_NAME}/fetchOptions`, {
            country_code: 'SA',
            city_id: cityId.value,
            weight: weight.value,
          })
            .then(response => {
              isLoading.value = false
              options.value = response.data.data.shipping_options
            })
            .catch(() => {
              isLoading.value = false
            })
        }
      })
    }

    const reset = () => {
      options.value = null
    }

    return {
      onSubmit,
      reset,
      options,
      cityId,
      weight,
      isLoading,
      // Refs
      shippingOptionsCalculator,
    }
  },
}
</script>
